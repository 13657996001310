import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

var classNames = require("classnames")
var showdown = require("showdown")

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

class MarkdownSection extends React.Component {
  constructor(props) {
    super(props)
  }
  

  render() {
    return (
      <section
        className="body-sections section joshua-tree-content"
        style={{
          paddingTop: this.props.paddingTop,
          paddingBottom: this.props.paddingBottom
        }}
      >
        <div className="columns">
          <div className={`column is-${this.props.sideColumnIs}`}></div>
          <MarkdownViewer
            className="column"
            markdown={this.props.textSection}
          />
          <div className={`column is-${this.props.sideColumnIs}`}></div>
        </div>
      </section>
    )
  }
}

MarkdownSection.propTypes = {
  textSection: PropTypes.string.isRequired,
  sideColumnIs: PropTypes.number
}

export default MarkdownSection
